import { Route, Switch } from "react-router-dom";
import ScrollToTop from "./Helpers/ScrollToTop";
import AboutPage from "./pages/AboutPage/AboutPage";
import ApartmentReservePage from "./pages/ApartmentReservePage/ApartmentReservePage";

import HomePage from "./pages/HomePage/HomePage";
import RegisterPage from "./pages/RegisterPage/RegisterPage";
import MainRedirect from "./components/MainRedirect/MainRedirect";
import Page404 from "./pages/Page404/Page404";
// import SiteAuthorizationPage from "./pages/SiteAuthorizationPage/SiteAuthorizationPage";

const AppRouter = () => {
	return (
		<>
			<ScrollToTop />
			<Switch>
				{/* <Route path="/authorization">
					<SiteAuthorizationPage />
				</Route> */}
				<Route exact path="/404">
					<Page404 />
				</Route>

				<Route exact path="/:projectSlug">
					<HomePage />
				</Route>

				<Route path="/:projectSlug/register">
					<RegisterPage/>
				</Route>

				<Route path="/:projectSlug/about">
					<AboutPage />
				</Route>

				<Route exact path="/:projectSlug/meeting/:meetingSlug">
					<MainRedirect />
				</Route>

				<Route path="/:projectSlug/:step?/:propertySlug?">
					<ApartmentReservePage />
				</Route>

				<Route exact path="/">
					<Page404 />
				</Route>
				
				<Route path="*">
					<Page404 />
				</Route>
			</Switch>
		</>
	);
};

export default AppRouter;
