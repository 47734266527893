import LayoutSplitScreenImage from "../../components/Layout/LayoutSplitScreen/LayoutSplitScreenImage";
import LayoutSplitScreenContent from "../../components/Layout/LayoutSplitScreen/LayoutSplitScreenContent";
import MainTitle from "../../components/UI/Titels/MainTitle";
import "./RegisterPage.sass";
import UserDetailsFormFields from "../../components/General/UserDetailsForm/UserDetailsFormFields";
import DateSelectionFormFields from "../../components/General/UserDetailsForm/DateSelectionFormFields";
import PageContentWrapper from "../../components/Layout/PageContentWrapper";
import { handleRegisterInputs, handleRegisterForm } from "../../Helpers/RegistetUtils";
import { useSelector } from "react-redux";
import { useEffect, useState, useCallback } from "react";
import SuccessPage from "./SuccessPage";
import { setLoadingModalContent, setFlowErrorObject } from "../../Helpers/AppartmentReserveUtils";
import { handleSetToken } from "../../Helpers/verificationUtils";
import ProjectLandingInit, { getWithExpiry } from "../../Helpers/ProjectLanding";
import { useParams } from "react-router-dom";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { makePostRequest } from "../../Axios";
import FlowErrorModal from "../../components/Modals/FlowErrorModal/FlowErrorModal";
import LoadingModal from "../../components/Modals/LoadingModal/LoadingModal";
import GTPageView from "../../Helpers/GTPageView";
import StepsMenu from "../../components/Navigation/StepsMenu/StepsMenu";
import $ from 'jquery';
// import SiteAuthorizationPage from "../SiteAuthorizationPage/SiteAuthorizationPage";
// import store from "../../Redux/Store/store";
// import * as projectActions from "../../Redux/Reducers/projectSlice";

const RegisterPage = () => {
	const { setEmail, setFirstName, setID, setLastName, setMobileNumber, setIsTermsAgreed } = handleRegisterInputs();
	const formSelector = useSelector((state) => state.register);
	const terms = useSelector((state) => state.project.projectSettings.project_agreement_termspdf);
	const meeting_duration = useSelector((state) => state.project.projectSettings.meeting_duration);
	const registerSuccess = useSelector((state) => state.register.registerSuccess);
	const [pageState, setPageState] = useState(false);
	const currentLanguge = useSelector((state) => state.project.currentLanguge);

	const projectSettings = useSelector((state) => state.project.projectSettings);
	const [isOk, setIsOk] = useState(true);
	let { projectSlug } = useParams();
	let phonePropName = "registerPhoneInput";

	// captch start
	const { executeRecaptcha } = useGoogleReCaptcha();
    const [token, setToken] = useState('');
    const [actionToChange, setActionToChange] = useState('');
 
    const registerUser = useCallback(async (e) => {
		e.preventDefault();
        if (!executeRecaptcha) {
            return;
        }
        const result = await executeRecaptcha('register');
		setToken(result);
		
		let data = new FormData();
		data.append("token", result);
		data.append("action", 'register');

		const req = await makePostRequest("/validate_recaptcha", data);
		if (!req) {
			return;
		}
		if (req.data.message == 'OK') {
			handleRegisterForm(e, currentLanguge);
		} else {
			setFlowErrorObject({errorCode:"100",errorMessage:currentLanguge.formsErrors.botError, errorTitle: ""})
		}
		
    }, [executeRecaptcha, currentLanguge]);
 
	const handleTextChange = useCallback(event => {
        setActionToChange(event.target.value);
	}, []);
	
	useEffect(() => {
		if ((!projectSettings.project_slug || projectSettings.project_slug == '') && isOk) {
			// get project data
			setLoadingModalContent(true);

			const asyncCall = async () => {
				handleSetToken().then(async () => {
					const res = await ProjectLandingInit(projectSlug, true);
					if (!res || res.data.projectinfo.length == 0) {
						window.location.href = '/404';
					}
					setLoadingModalContent(false);
				});
			};
			asyncCall();
		} else {
			setLoadingModalContent(false);
		}
	 }, [projectSettings, projectSlug]);


	useEffect(() => { }, [currentLanguge]);
	

	useEffect(() => {
		GTPageView('register page');
	}, []);

	useEffect(() => {
		if (registerSuccess == true) {
			setPageState(registerSuccess);	
		} 
	}, [registerSuccess]);

 
    useEffect(() => {
        if (!executeRecaptcha) {
            return;
        }
        const handleReCaptchaVerify = async () => {
            const token = await executeRecaptcha('register');
			setToken(token);
        };
        handleReCaptchaVerify();
    }, [executeRecaptcha]);
	// captch end
	
	useEffect(() => {
		if (window.hasOwnProperty("$")) {
			window.$("#steps_menu__container").sticky({ topSpacing: 90, bottomSpacing: 140 });
		}

		const urlParams = new URLSearchParams(window.location.search);
		const infllow = urlParams.get('infllow')

		if (window.location.pathname.includes('/register/') && infllow == '1') {
			$('#sideImageLogin-sticky-wrapper').addClass('smaller_bg');
		} else if (window.location.pathname.includes('/register/') && infllow != '1') {
			$('#sideImageLogin-sticky-wrapper').removeClass('smaller_bg');
		}
	}, []);

	// const siteAuthorization = useSelector((state) => state.project.siteAuthorization);
	// if (!siteAuthorization || !getWithExpiry('siteAuthorization_expiry')) {
	// 	store.dispatch(projectActions.setSiteAuthorization(false));
	// 	return <SiteAuthorizationPage />;
	// }
	
	if (!isOk) {
		window.location.href = `/404`;
	}

	return (
			<PageContentWrapper id="register_page">
			{/* Content Col */}
				<FlowErrorModal/>
				<LoadingModal />
			
				{/* Steps Menu  */}
				<div className="p-0" style={{maxWidth: '120px', minWidth: '120px'}} id="steps_menu__col">
					<StepsMenu id="steps_menu__container" projectSettings={projectSettings} />
				</div>
				<LayoutSplitScreenContent id="register_pageContent" className="py-5 px-4">
					{pageState === true ? (
						<SuccessPage />
					) : (
						<>
							<MainTitle>{currentLanguge.register.title}</MainTitle>
							<p className="my-1 mb-1 pfItemFont">
								<img src={`${window.location.origin}/assets/img/icons/ico-meeting_duration.svg`} className="pfIcon mde-1" height="22" width="22" alt={currentLanguge.register.titleIcon1}/> {currentLanguge.register.titleIcon1} {meeting_duration} {currentLanguge.general.timeMinutes}
							</p>
							<p className="my-1 pfItemFont">
								<img src={`${window.location.origin}/assets/img/icons/ico-meeting_details.svg`} className="pfIcon mde-1" height="22" width="22" alt={currentLanguge.register.titleIcon2}/> {currentLanguge.register.titleIcon2}
							</p>

							{/* Registration Form */}
							<form id="register_form" className="my-5" onSubmit={(e) => registerUser(e)}>
								{/* Client Details */}
								<UserDetailsFormFields
									setEmail={setEmail}
									setMobileNumber={setMobileNumber}
									setFirstName={setFirstName}
									setLastName={setLastName}
									setID={setID}
									formSelector={formSelector}
									phonePropName={phonePropName}
									handleTextChange={handleTextChange}
								/>

								{/* Terms */}
								<div className="row mt-3 mb-5">
									<div className="col">
										<div className="form-check">
											<input
												className="form-check-input"
												checked={formSelector.termsAgreed}
												type="checkbox"
												value={formSelector.termsAgreed}
												id="register_form__terms"
												onChange={(e) => setIsTermsAgreed(e.target.checked)}
											/>
											<label className="form-check-label" htmlFor="register_form__terms">
												{currentLanguge.formsFields.terms}{" "}
												<a href={`${terms}`} target="_blank" rel="noreferrer">
													{currentLanguge.formsFields.termsLink}
												</a>
											</label>
										</div>
									</div>
									<div id="invalidTerms" className="invalidField"></div>
								</div>

								{/* Date Selection */}
								<DateSelectionFormFields projectID={projectSlug} />
								<div id="invalidDate" className="invalidField"></div>
								<div id="invalidTime" className="invalidField"></div>

								<div className="row justify-content-center pt-4">
									<div className="col-lg-6 col-sm-12 text-center">
										<div className="d-grid">
											<input type="submit" id="projectRegisterButton" value={currentLanguge.formsCTA.registerSubmitBtn} className="btn rf-btn-primary fw-bold f18 py-3 pfSubmitBtnColor" />
										</div>
									</div>
								</div>
							</form>
						</>
					)}
				</LayoutSplitScreenContent>

				{/* Background img Col */}
				<LayoutSplitScreenImage backgroundImage={projectSettings.project_image_desktop} backgroundImageAlt="side image" />
			</PageContentWrapper>
	);
};

export default RegisterPage;
