import MessageBar from "../../components/General/MessageBar/MessageBar";
import StepsMenu from "../../components/Navigation/StepsMenu/StepsMenu";
import DisplayStep from "../../Helpers/AppartmentReserveUtils/DisplayStep";
import {
	setShowApprovePopup,
	setShowNav,
	setShowFooterLinks,
	setStepProgression,
	setStepShort,
	setUserApprovedApt,
	setRemoteAction,
	setLoadingModalContent,
	setGetAgentStatus,
	setUserVerified,
	changeMeetingAgentControl,
	setShowVideoLoadingModal,
	setVideoLoadingModalMessage,
	setLoadingIsDone,
	stepsDictionary,
	getObjKey,
	handleTableAppartmentView,
	addURLHistory
} from "../../Helpers/AppartmentReserveUtils";
import { useEffect, useState, useCallback } from "react";
import { BAL, handleSetToken, setIsClientRegistered, setSlug } from "../../Helpers/verificationUtils";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import LoadingModal from "../../components/Modals/LoadingModal/LoadingModal";
import "./ApartmentReservePage.sass";
import DetailsApprovalModal from "../../components/ApartmentReserveSteps/DetailsApproval/DetailsApprovalModal/DetailsApprovalModal";
import $ from "jquery";
import FlowErrorModal from "../../components/Modals/FlowErrorModal/FlowErrorModal";
import { persistor } from "../../Redux/Store/store";
import store from "../../Redux/Store/store";
import GeneralModal from "../../components/Modals/GeneralModal/GeneralModal";
import ApartmentSelection3D from "../../components/ApartmentReserveSteps/ApartmentSelection3D/ApartmentSelection3D";
import VideoComponent from "../../components/MeetingComponents/VideoComponent/VideoComponent";
import VideoComponentMobile from "../../components/MeetingComponents/VideoComponentMobile/VideoComponentMobile";
import GTPageView from "../../Helpers/GTPageView";
import { setAgentControlChanged } from "../../Helpers/MeetingsUtils";


import { setMeetingStatus, setAgentControl, setMeetingObj } from "../../Helpers/MeetingsUtils";
import { setScreenShareInRoom, updateAudioDevice, updateVideoDevice, resetHolders, handleSetMeetingAgentControl } from "../../components/MeetingComponents/Utils";
import Video, { createLocalTracks } from "twilio-video";
import { getVideoDevices, getAudioDevices, getOutputDevices } from "../../components/MeetingComponents/VideoControllers";
import Tour from "../../components/General/Tour/Tour";
import ProjectLandingInit, { getWithExpiry } from "../../Helpers/ProjectLanding";
import * as registerActions from "../../Redux/Reducers/registerSlice";
import * as verificationActions from "../../Redux/Reducers/verificationSlice";
import * as projectActions from "../../Redux/Reducers/projectSlice";
import * as meetingActions from "../../Redux/Reducers/meetingSlice";
import { makeGetRequest } from "../../Axios";
// import SiteAuthorizationPage from "../SiteAuthorizationPage/SiteAuthorizationPage";

const ApartmentReservePage = (props) => {
	const isMobile = useSelector((state) => state.project.isMobile)
	const tokenIsSet = useSelector((state) => state.project.tokenIsSet)
	const finishLoad = useSelector((state) => state.project.finishLoad)
	const obj = useSelector((state) => state.project.projectSettings);
	const objproject = useSelector((state) => state.project);
	const meetingAagentControlChanged = useSelector((state) => state.meeting.agentControlChanged);
	const meetingStatus = useSelector((state) => state.meeting.meetingStatus);
	const hideAgentPanelState = useSelector((state) => state.project.projectSettings.hide_agent_panel)
	const flowType = useSelector((state) => state.project.flowType)
	const currentLanguge = useSelector((state) => state.project.currentLanguge)
	const isAgent = useSelector((state) => state.meeting.isAgent);

	const token = useSelector((state) => state.verification.workingToken);
	const meetingRoom = useSelector((state) => state.meeting.roomSpaceID);
	const agentControl = useSelector((state) => state.meeting.agentControl);

	const selectedSpeaker = useSelector((state) => state.register.selectedSpeaker);
	const selectedCamera = useSelector((state) => state.register.selectedCamera);
	const selectedMic = useSelector((state) => state.register.selectedMic);
	
	const [isAgentSynced, setIsAgentSynced] = useState(false);
	const [showAgentModal, setShowAgentModal] = useState(false);
	const [istoken, setistoken] = useState(false);
	const [showAgentForm, setshowAgentForm] = useState(false);
	const [isLoaded, setIsLoaded] = useState(false);
	const [isAgentSyncSent, setIsAgentSyncSent] = useState(false);
	
	const { propertySlug } = useParams();
	const { step } = useParams();
	const { projectSlug } = useParams();
	
	const allowedSteps = [1, 4, 9, 10, 11];
	// const siteAuthorization = useSelector((state) => state.project.siteAuthorization);
	
	useEffect(() => {
		let localVideo = document.getElementById("localVideo");
		let remoteVideo = document.getElementById("remoteVideo");

		if (localVideo && remoteVideo && !isLoaded) {
			setIsLoaded(true);
		}
	});

	useEffect(() => {
		$("#apartment_view_change__desktop").show();
		$("#apartment_view_change__mobile").show();
		$(".stats_container").show();
		GTPageView('Apartment Reserve page');
	}, []);
	
	useEffect(() => {
		const asyncCall = async () => {
			handleSetToken().then(async () => {
				setistoken(true);
				const res = await ProjectLandingInit(projectSlug);
				
				if (res.data && res.data.colors_theme.length == 0 && res.data.projectinfo.length == 0) {
					store.dispatch(verificationActions.reset());
					store.dispatch(meetingActions.reset());
					store.dispatch(registerActions.reset());

					setShowNav(false);
					setShowFooterLinks(true);

					window.location.href = '/404';
				}

				setLoadingIsDone(true);
				setShowNav(true);
				setShowFooterLinks(true);

				// set step
				let stepCode = stepsDictionary[`${step}`];

				const getUrlParams = new URLSearchParams(window.location.search);
				const agentKey = getUrlParams.get("agentkey");

				if (agentKey && !isAgentSyncSent) return;

				if (!allowedSteps.includes(stepCode)) {
					// check if step included in allowedSteps array - go to building selection
					let firstStep_title = getObjKey(stepsDictionary, 1);
					window.location.href = `/${projectSlug}/${firstStep_title}`;
				} else if (stepCode == 11) {
					// if step 11 (3dview): show 3dView
					store.dispatch(projectActions.setShow3DView(true));
					$('.view3DViewButton').addClass('activeView');
					
					setLoadingModalContent(false);
				} else if (stepCode == 4 && propertySlug != '') {
					// if step 4 (apartment_details): check for apt slug && set selections
					loadProperyData();
				} else if (stepCode == 10 || stepCode == 9) {
					// if step 4 (apartment_details): check for apt slug && set selections
					setStepShort(`${stepCode}`);
				}
				// if step 2 (floor_selection): check for building selection param && set selections
				// if step 3 (apartment_selection): check for building selection param and floor selection param && set selections
			});
		};
		
		if (!projectSlug || (projectSlug && (!step || !stepsDictionary[`${step}`] || stepsDictionary[`${step}`] == '' ))) {
			window.location.href = '/404';
		} else if (!finishLoad) {
			setLoadingModalContent(true);
			asyncCall();
		} else {
			setShowNav(true);
		}

		const urlParams = new URLSearchParams(window.location.search);
		const showAgentForm = urlParams.get('showAgentForm')

		if (showAgentForm && showAgentForm == 1) {
			setshowAgentForm(true);
		}
	},[finishLoad, objproject, isAgentSyncSent])


	

	const loadProperyData = async () => {
		setLoadingModalContent(true);
		let langCode = sessionStorage.getItem("langCode");
		if (!langCode || langCode == '') {
			langCode = '';
		} else {
			langCode = '/' + langCode;
		}

		const res = await makeGetRequest("/properties_short", `${propertySlug}${langCode}`).catch((err) => console.log(err));
		if (!res) {
			console.error(res);
			return;
		}
		
		const resDynamicData = await makeGetRequest(`/projectstatus`, `${projectSlug}${langCode}`).catch((err) => console.log(err));
		if (!resDynamicData) {
			console.error(resDynamicData);
			return;
		}


		let dynamicAppartmentsData = resDynamicData.data[0].buildings;

		let selectedBuilding = dynamicAppartmentsData.filter((building) => {
			if (parseInt(building.building_id) === parseInt(res.data.propertydata.building_id)) {
				return building;
			}
		});

		let selectedFloor = selectedBuilding[0].floors.filter((floor) => {
			if (parseInt(floor.floor_id) === parseInt(res.data.propertydata.floor_id)) {
				return floor;
			}
		})

		let payload = {
			buildingID: res.data.propertydata.building_id,
			floorID: res.data.propertydata.floor_id,
			slug: propertySlug,
			id: res.data.propertydata.id,
			buildingTitle: selectedBuilding[0].building_title,
			floorTitle: selectedFloor[0].floor_title,
			aptTitle: res.data.propertydata.property_title,
		};

		handleTableAppartmentView(payload, currentLanguge, false);
		setLoadingModalContent(false);
	};

	useEffect(() => {
		if (window.hasOwnProperty("$")) {
			window.$("#steps_menu__container").sticky({ topSpacing: 90, bottomSpacing: 140 });
			window.$("#meetingellm").sticky({ topSpacing: 90, bottomSpacing: 140, wrapperClassName: "p-0" });
		}
	}, [finishLoad]);

	useEffect(() => {
		document.addEventListener("beforeunload", function (e) {
			// If you prevent default behavior in Mozilla Firefox prompt will always be shown
			// Chrome requires returnValue to be set
			e.returnValue = "";
		});

		document.addEventListener("openApprovePop", (e) => {
			if (e.detail != false) {
				store.dispatch(registerActions.resetForm());

				store.dispatch(registerActions.setEmail(e.detail.email));
				store.dispatch(registerActions.setAreaCode(e.detail.areaCode));
				store.dispatch(registerActions.setMobileNumber(e.detail.mobileNumber));
				store.dispatch(registerActions.setFirstName(e.detail.firstName));
				store.dispatch(registerActions.setLastName(e.detail.lastName));
				store.dispatch(registerActions.setID(e.detail.ID));
				store.dispatch(registerActions.setCountry(e.detail.country));
				store.dispatch(registerActions.setTerms(e.detail.termsAgreed));
				store.dispatch(registerActions.setTimezone(e.detail.timezone));
				store.dispatch(registerActions.setIpAddress(e.detail.ipAddress));
				store.dispatch(registerActions.setRegisterSuccess(e.detail.isRegisterd));
				store.dispatch(registerActions.setMobileNumberWithoutPrefix(e.detail.mobileNumberWithoutPrefix));
				store.dispatch(registerActions.setIsClientVerified(e.detail.isClientVerified));
				store.dispatch(registerActions.setCodeRegisterStep(e.detail.codeRegisterStep));
				store.dispatch(registerActions.setEditClientDetails(e.detail.editClientDetails));

				store.dispatch(registerActions.setBuyerFirstName(e.detail.buyerFirstName));
				store.dispatch(registerActions.setBuyerLastName(e.detail.buyerLastName));
				store.dispatch(registerActions.setBuyerID(e.detail.buyerID));
				store.dispatch(registerActions.setBuyerEmail(e.detail.buyerEmail));
				store.dispatch(registerActions.setBuyerMobileNumber(e.detail.buyerMobileNumber));
				store.dispatch(registerActions.setBuyerMobileNumberWithoutPrefix(e.detail.buyerMobileNumberWithoutPrefix));
				store.dispatch(registerActions.setBuyerAreaCode(e.detail.buyerAreaCode));
				store.dispatch(registerActions.setIsDifferentBuyer(e.detail.isDifferentBuyer));
			}
			setShowApprovePopup(e.detail == false ? false : true);
		});
		document.addEventListener("setApprovePop", (e) => {
			setUserApprovedApt(e.detail);
			setShowApprovePopup(false);
		});
		document.addEventListener("setShowAgentModal", (e) => {
			setShowAgentModal(e.detail);
		});

		return () => {
			document.removeEventListener("openApprovePop", () => {});
			document.removeEventListener("setApprovePop", () => {});
			document.removeEventListener("beforeunload", () => {});
			document.removeEventListener("setShowAgentModal", () => {});
		};
	}, []);

	useEffect(() => {
		document.addEventListener("stepChange", (e) => {
			e.preventDefault();
			e.stopPropagation();

			setStepShort(`${e.detail}`);
			let step_title = getObjKey(stepsDictionary, e.detail);
			addURLHistory(step_title, false)
			persistor.flush();

			const stepsButtons = document.querySelectorAll(".stepsButton");

			stepsButtons.forEach((el) => {
				if (parseFloat(el.dataset.step) == parseFloat(e.detail)) {
					el.classList.add("current-link", "complate-link");
				} else if (parseFloat(el.dataset.step) !== parseFloat(e.detail)) {
					el.classList.remove("current-link");
				}

				if (parseFloat(el.dataset.step) == 1 && !$(el).hasClass('doneStep')) { 
					el.classList.add("complate-link");
				}
			});

			if (e.detail == 5.5) {
				setShowAgentModal(false);
			}
		});

		return () => {
			document.removeEventListener("stepChange", function () {});
		};
	}, [agentControl]);

	// [1] Agent: send 'reqSyncFlow' pusher event 
	useEffect(() => {
		const getUrlParams = new URLSearchParams(window.location.search);
		const agentKey = getUrlParams.get("agentkey");
		
		// sync if NOT pre-scheduled meeting
		if (agentKey && !isAgentSyncSent && tokenIsSet == true) {
			setRemoteAction({ eventName: "reqSyncFlow", payload: true, author: true }, false);
			setIsAgentSyncSent(true);
		} else if (!agentKey)  {
			setIsAgentSyncSent(true);
		}
	}, [tokenIsSet])

	// [2] Client: recive pusher event and sending data to remoteAction
	useEffect(() => {
		document.addEventListener("reqSyncFlow", (e) => {
			
			const getUrlParams = new URLSearchParams(window.location.search);
			const agentKey = getUrlParams.get("agentkey");
			if (!agentKey) {
				let syncData = {
					appartmantTitle: e.detail.project.appartmantTitle,
					appartmentBasicData: e.detail.project.appartmentBasicData,
					appartmentID: e.detail.project.appartmentID,
					appartmentRealID: e.detail.project.appartmentRealID,
					buildingID: e.detail.project.buildingID,
					buildingName: e.detail.project.buildingName,
					currentStep: e.detail.project.currentStep,
					stepsProgress: e.detail.project.stepsProgress,
					floorID: e.detail.project.floorID,
					floorName: e.detail.project.floorName,
					selectedAptImg: e.detail.project.selectedAptImg,
					selectedFloorImg: e.detail.project.selectedFloorImg,
					meetingObj: e.detail.meeting
				}
				setRemoteAction({ eventName: "syncFlow", payload: syncData, author: false });
			}
		});

		return () => {
			document.removeEventListener("reqSyncFlow", () => {});
		};
	}, [])
	
	// [3] Agent: syncing data for the agent 
	useEffect(() => {
		document.addEventListener("syncFlow", (e) => {
			const getUrlParams = new URLSearchParams(window.location.search);
			const agentKey = getUrlParams.get("agentkey");

			if (agentKey && !isAgentSynced) {
				store.dispatch(projectActions.setStep(e.detail.currentStep));
				store.dispatch(projectActions.setStepsProgress(e.detail.stepsProgress));
				
				let step_title = getObjKey(stepsDictionary, parseFloat(e.detail.currentStep));
				if(step_title != '') addURLHistory(step_title, false)
				
				
				if (parseFloat(e.detail.stepsProgress) > 1) {
					store.dispatch(projectActions.setBuldingID(e.detail.buildingID));
					store.dispatch(projectActions.setBuldingName(e.detail.buildingName));	
				}

				if (parseFloat(e.detail.stepsProgress) > 2) {
					store.dispatch(projectActions.setFloorID(e.detail.floorID));
					store.dispatch(projectActions.setFloorName(e.detail.floorName));
					store.dispatch(projectActions.setSelectedFloorImg(e.detail.selectedFloorImg));
				}

				if (parseFloat(e.detail.stepsProgress) > 3) {
					store.dispatch(projectActions.setAppartmentTitle(e.detail.appartmantTitle));
					store.dispatch(projectActions.setAppartmentBasicaData(e.detail.appartmentBasicData));
					store.dispatch(projectActions.setAppartmentID(e.detail.appartmentID));
					store.dispatch(projectActions.setAppartment_id(e.detail.appartmentRealID));
					store.dispatch(projectActions.setSelectedAptImg(e.detail.selectedAptImg));
				}

				setMeetingObj(e.detail.meetingObj);
				
				setIsAgentSynced(true);

			}
		});
		return () => {
			document.removeEventListener("syncFlow", () => {});
		};
	}, []);

	// [4] Agent: remove loader
	useEffect(() => {
		if (isAgentSynced) {
			setLoadingModalContent(false);
		} 
	}, [isAgentSynced])


	// Client: set agent control
	// TODO: AgentLogic
	useEffect(() => {
		if (flowType == 'self') return;
		const getUrlParams = new URLSearchParams(window.location.search);
		const agentKey = getUrlParams.get("agentkey");
		
		if (!agentKey) {
			// setAgentControl trigger
			setAgentControl(true);
			setAgentControlChanged(true);
		}
	},[flowType])

	// Agent: set agent control 
	// TODO: AgentLogic
	useEffect(() => {
		const getUrlParams = new URLSearchParams(window.location.search);
		const agentKey = getUrlParams.get("agentkey");
		
		if (!propertySlug && agentKey && !meetingAagentControlChanged && tokenIsSet == true) {
			handleSetMeetingAgentControl(true);
			
			// setAgentControl trigger
			console.log('setAgentControl trigger - Agent: set agent control ');
			setAgentControl(true);
			setAgentControlChanged(true);
		} else  if(agentKey && meetingAagentControlChanged && tokenIsSet == true){
			setAgentControl(false);
		}
	}, [meetingAagentControlChanged, tokenIsSet])

	
	// video/audio tracks and set conection
	let callbacked = useCallback(async () => {
		let tracks;
		if (selectedCamera != '' && selectedMic != '') {
			tracks = await createLocalTracks({ video: { deviceId: { exact: selectedCamera } }, audio: { deviceId: { exact: selectedMic } } });
		} else {
			tracks = await createLocalTracks({ video: true, audio: true });
		}
		let room = await Video.connect(token, {
			name: meetingRoom,
			tracks,
			automaticSubscription: true,
		}).catch((err) => console.log(err));
		return { room, tracks };
	}, [meetingRoom]);

	const setVideoLoadingData = useCallback((show, message) => {
		setShowVideoLoadingModal(show);
		setVideoLoadingModalMessage(message);
	});

	// pusher setting and conection
	useEffect(async () => {
		try {
			if(meetingStatus == 'start' && meetingRoom && isLoaded){
				window.onbeforeunload = function (e) {
					room.disconnect();
					e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
					// Chrome requires returnValue to be set
					e.returnValue = "";
				};

				// Start Video conection and view
				setVideoLoadingData(true, currentLanguge.meetingVideoComp.prepingMsg);

				const localVideo = document.getElementById("localVideo");
				const remoteVideo = document.getElementById("remoteVideo");
				let localVideoVideoelem = localVideo.querySelector("video");

				let { room, tracks } = await callbacked();
				let localTrack = tracks.find((track) => track.kind === "video");
				let audioTrack = tracks.find((track) => track.kind === "audio");
				window.room = room;

				if (localVideoVideoelem) {
					localVideo.innerHTML = "";
				}
				let elems = document.querySelectorAll(".removePointer");
				elems.forEach((el) => {
					el.classList.remove("removePointer");
				});
				localVideo.appendChild(localTrack.attach());
				
				setVideoLoadingData(false, "");
				// End Video conection and view

				// clear meeting after 1 min is no particpants 
				setTimeout(() => {
					if (room.participants.size === 0) {
						if (!isAgent) {
							setGetAgentStatus(false);
							setMeetingStatus(false);
							setSlug("");
							setIsClientRegistered(false);
							setUserVerified(false);
							persistor.flush();
						}
					}
				}, 600000);


				//LOCAL HANDLERS
				room.participants.forEach((participant) => {
					participant.tracks.forEach((publication) => {
						if (publication.track) {
							resetHolders(publication.track);
							remoteVideo.appendChild(publication.track.attach());
						}
					});
					participant.on("trackSubscribed", (track) => {
						resetHolders(track);
						remoteVideo.appendChild(track.attach());
					});

					participant.on("trackUnsubscribed", (track) => {});
					participant.on("trackUnpublished", (track) => {});
					participant.on("trackPublished", (track) => {});
				});

				// Local - Share screen
				room.on("share", () => {
					setScreenShareInRoom(room, localTrack);
				});

				// Local - change camera
				room.on("changeVideoInput", (data) => {
					updateVideoDevice(data, localTrack);
				});

				// Local - change min
				room.on("changeAudioInput", (data) => {
					updateAudioDevice(data, audioTrack);
				});

				// Local - Get Source
				room.on("getSource", () => {
					Video.createLocalTracks({ audio: true, video: true }).then((stream) => {
						room.localParticipant.publishTracks(stream);
					});
				});

				// Local - Mute
				room.on("mute", () => {
					room.localParticipant.audioTracks.forEach((track) => {
						track.track.disable();
					});
				});
				// Local - Unmute
				room.on("unmute", () => {
					room.localParticipant.audioTracks.forEach((track) => {
						track.track.enable();
					});
				});
				// Local - Stop video
				room.on("stopVideo", () => {
					room.localParticipant.videoTracks.forEach((track) => {
						track.track.disable();
					});
				});
				// Local - Resume video
				room.on("resumeVideo", () => {
					room.localParticipant.videoTracks.forEach((track) => {
						track.track.enable();
					});
				});

				//REMOTE HANDLRES
				room.on("participantConnected", (participant) => {
					console.log('REMOTE HANDLRES');
					let loaderMsg = isAgent ? currentLanguge.meetingVideoComp.addingNewUserToMeeting : currentLanguge.meetingVideoComp.addingNewAgentToMeeting;
					setVideoLoadingData(true, loaderMsg);

					participant.tracks.forEach((publication) => {
						if (publication.track) {
							let track = publication.track;

							resetHolders(track);
							remoteVideo.appendChild(publication.track.attach());
						}
					});
					participant.on("trackPublished", (track) => {
						console.log('REMOTE participant - trackPublished');
						if (track.track) {
							let thetrack = track.track;
							resetHolders(thetrack);
							remoteVideo.appendChild(thetrack.attach());
							setVideoLoadingData(false, "sa");
						}
					});
					participant.on("trackSubscribed", (track) => {
						console.log('REMOTE participant - trackSubscribed');
						resetHolders(track);
						remoteVideo.appendChild(track.attach());
						setVideoLoadingData(false, '');
					});

					participant.on("trackUnsubscribed", (track) => {
						console.log('REMOTE participant - trackUnsubscribed');
						// room.emit("backtovideo", track);
					});
					participant.on("trackUnpublished", (track) => {
					});
					setTimeout(() => {
						setVideoLoadingData(false, "");
					}, 2000);
 
					participant.on("disconnected", function () {
						console.log('REMOTE participant - disconnected');

						let loaderMsg = isAgent ? currentLanguge.meetingVideoComp.userLeftMeeting : currentLanguge.meetingVideoComp.agentLeftMeeting;
						setVideoLoadingData(true, loaderMsg);
						
						// AgentLogic
						changeMeetingAgentControl(false);
						setAgentControl(false);
						
					});
				});

				room.once("participantDisconnected", (participant) => {
					console.log('room - participantDisconnected');
					// let loaderMsg = isAgent ? currentLanguge.meetingVideoComp.userLeftMeeting : currentLanguge.meetingVideoComp.agentLeftMeeting;
					// setVideoLoadingData("alright", loaderMsg);
					
					// AgentLogic
					// changeMeetingAgentControl(false);
					// setAgentControl(false);

				});
				room.once("disconnected", function (participant) {
					console.log('room - disconnected');
				
					// AgentLogic
					// changeMeetingAgentControl(false);
					// setAgentControl(false);
				});
			}
		} catch (err) {
			console.log(err);
		}
	}, [meetingRoom, meetingStatus, isLoaded]);

	// get media devices
	useEffect(() => {
		const getDevices = async () => {
			const devices = await navigator.mediaDevices.enumerateDevices();
			getVideoDevices(devices, "camDropdown", selectedCamera);
			getAudioDevices(devices, "micDropdown", selectedMic);
			getOutputDevices(devices, "ouptputDropdown", selectedSpeaker); 
		};

		if(meetingStatus == 'start' && meetingRoom && isLoaded) getDevices();
	}, [meetingRoom, meetingStatus, isLoaded]);


	const toggleComponent = (e) => {
		if (!$('#meetingellm__toggle').hasClass('toggle_open')) {
			$('#appContainer').addClass('w-100');
			$('#meetingellm__toggle').addClass('toggle_open');
			$('#meetingellm__target').addClass('hidden');
		} else {
			$('#appContainer').removeClass('w-100');
			$('#meetingellm__toggle').removeClass('toggle_open');
			$('#meetingellm__target').removeClass('hidden');
		}
	}

	// if (!siteAuthorization || !getWithExpiry('siteAuthorization_expiry')) {
	// 	store.dispatch(projectActions.setSiteAuthorization(false));
	// 	return <SiteAuthorizationPage />;
	// }

	if (!finishLoad) {
		return (
			<div className="container-fluid p-0" id="page_apartment_reserve">
				<LoadingModal />
			</div>
		)
	}
	return (
		<div className="container-fluid p-0" id="page_apartment_reserve">
			<VideoComponentMobile showAgentForm={showAgentForm} />
			<BAL istoken={istoken} />
			<DetailsApprovalModal />
			<GeneralModal showAgentModal={showAgentModal} />
			<LoadingModal />
			<FlowErrorModal />

			{/* <Tour/> */}
			<div className="row m-0 p-0 mt-3 position-relative flex-nowrap">
				{/* Main Content: menu and content  */}
				<div className={`${hideAgentPanelState == "1" || isMobile == true ? 'w-100': ''}`} id="appContainer" style={{width: 'calc(100% - 450px)'}}>
					<div className="row m-0 p-0">
						{/* Steps Menu  */}
						<div className="p-0" style={{maxWidth: '120px', minWidth: '120px'}} id="steps_menu__col">
							<StepsMenu id="steps_menu__container" projectSettings={obj} isAgentSynced={isAgentSynced} />
						</div>
						{/* Steps Main Content box  */}
						<div className="p-0 steps_content_container" >
							{/* Messages Bar  */}
							<div className="row m-0">
								<div className="col-auto p-0 w-100">
									<MessageBar />
								</div>
							</div>

							{/* Apartment Reservation Steps  */}
							<div className="row m-0" style={{flexGrow: '1'}}>
								<div className="col p-0 ">
									<div className="bg-white shadow position-relative h-100" >
										<DisplayStep setStepsProgress={setStepProgression} />
										<ApartmentSelection3D/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<a id='meetingellm__toggle' alt='Show Agent video component' onClick={toggleComponent} className={` ${meetingStatus === "start" ? 'inMeeting' : ''}`}
					data-gt-category="agent component" data-gt-action="click" data-gt-label="toggle component view">
					<i className="bi bi-chevron-double-left"></i>
				</a>
				{/* Video Container: Meeting With Agent Video and Controls  */}
				<VideoComponent />
			</div>
		</div>
	);
};

export default ApartmentReservePage;
