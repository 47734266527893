import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
	setRemoteAction,
	setShowApprovePopup,
	setUserApprovedApt,
} from "../../../../Helpers/AppartmentReserveUtils";
import "./DetailsApprovalModal.sass";
import AppartmentShort from "../../../General/AppartmentShort/AppartmentShort";
import { persistor } from "../../../../Redux/Store/store";
import { handleNewClientShort, updateLeadData } from "../../../../Helpers/RegistetUtils";

const DetailsApprovalModal = () => {
	const [show, setShow] = useState(false);
	const meetingSlug = useSelector((state) => state.verification.slug);
	const showApprovePopup = useSelector((state) => state.project.showApprovePopup);
	const userApprovedApt = useSelector((state) => state.project.userApprovedApt);
	const isAgent = useSelector((state) => state.meeting.isAgent);
	const isSelfReserve = useSelector((state) => state.meeting.isSelfReserve);
	const agentControl = useSelector((state) => state.meeting.agentControl);
	const registerSelector = useSelector((state) => state.register);
    const currentLanguge = useSelector((state) => state.project.currentLanguge)
	const buyerID = useSelector((state) => state.register.buyerID);
	const buyerEmail = useSelector((state) => state.register.buyerEmail);
	const meetingStatus  = useSelector((state) => state.meeting.meetingStatus );

	useEffect(() => { }, [currentLanguge]);

	useEffect(() => {
		if (userApprovedApt) {
			setShowApprovePopup(false);
			setShow(false);
			return;
		}
		if (!userApprovedApt) {
			setShow(showApprovePopup);
			return;
		}
	}, [showApprovePopup, userApprovedApt]);

	useEffect(() => {
	},[buyerID, buyerEmail])

	const handleChangeSelection = (e) => {
		if (meetingSlug) {
			setRemoteAction({ eventName: "openApprovePop", payload: false, author: isAgent });
			setShowApprovePopup(false);
			return;
		}
		setShowApprovePopup(false);
	};


	const handleApprove = async (e) => {
		if (meetingSlug) {
			// update leed data.
			updateLeadData(currentLanguge);
			setRemoteAction({ eventName: "setApprovePop", payload: true, author: isAgent });
			return;
		}
		setUserApprovedApt(true);
		setRemoteAction({ eventName: "setApprovePop", payload: true, author: isAgent });
		handleNewClientShort(currentLanguge);
		persistor.flush();
		return;
	};

	const Phone = () => {
		try {
			if (registerSelector.buyerMobileNumberWithoutPrefix.includes('+') && currentLanguge.direction == 'rtl') {
				let mobileNumber =registerSelector.buyerMobileNumberWithoutPrefix.replace('+', '');
				return mobileNumber + '+';
			}
			return `${registerSelector.buyerMobileNumberWithoutPrefix}`;
		} catch (err) {
			console.log(err);
		}
	};

	const basicData = useSelector((state) => state.project.appartmentBasicData);

	return (
		<Modal show={show} contentClassName="detailsApprovalModalContainer d-flex flex-row" centered>
			<div className="approveModalBody col-7">
				<Modal.Header className="p-2 f20 w600 text-center">{currentLanguge.detailsApprovalModalContainer.title}</Modal.Header>
				<Modal.Body className="col-12 d-flex flex-column pt-0">
					<AppartmentShort id="approveModal" hideImage='true' />

					<div className="col-10 d-flex flex-column justify-content-start mb-2" style={{maxWidth:'280px'}}>
						<h4 className="f26 fw-bolder">{currentLanguge.detailsApprovalModalContainer.clientDetailsTitle}</h4>
						{/* <ApartmentFeatures /> */}
						<div className={`d-flex f16 `}><span className="fw-bold">{currentLanguge.formsFields.firstName}: </span><span className="pds-2">{registerSelector.buyerFirstName}</span></div>
						<div className={`d-flex f16 `}><span className="fw-bold">{currentLanguge.formsFields.lastName}: </span><span className="pds-2">{registerSelector.buyerLastName}</span></div>
						<div className={`d-flex f16 `}><span className="fw-bold">{currentLanguge.formsFields.ID}: </span><span className="pds-2">{registerSelector.buyerID}</span></div>
						<div className={`d-flex f16 `}><span className="fw-bold">{currentLanguge.formsFields.email}: </span><span className="pds-2">{registerSelector.buyerEmail}</span></div>
						<div className={`d-flex f16 `}><span className="fw-bold">{currentLanguge.formsFields.phone}: </span><span className="pds-2">{Phone()}</span></div>
					</div>
					<div className={`col-12 flex-row d-flex justify-content-center my-3`}>
						<button type="button" className=" w600 btn btn-light px-5 mde-1" disabled={((isAgent && !agentControl) || (!isAgent && agentControl)) && meetingStatus != false ? 'disabled' : ''} onClick={(e)=>handleChangeSelection(e)}
							data-gt-category='buyer verification' data-gt-action='click' data-gt-label='cancel approvel'>
							{currentLanguge.formsCTA.formaCancel}
						</button>
						<button type="button" className=" w600 btn rf-btn-primary px-5 mds-1" disabled={((isAgent && !agentControl) || (!isAgent && agentControl)) && meetingStatus != false ? 'disabled' : ''} onClick={(e)=>handleApprove(e)}
							data-gt-category='buyer verification' data-gt-action='click' data-gt-label='confirm approvel'>
							{currentLanguge.formsCTA.formApprove}
						</button>
					</div>
					{/* <div className="col-12 f12 d-flex flex-row  text-center justify-content-center mt-1">
						{currentLanguge.detailsApprovalModalContainer.footerText}
					</div> */}
				</Modal.Body>
			</div>
			<div id="approveModalImage" className=" col-5">
				<img src={basicData.apartment_image_desktop} className="imgObjFitCoverCenter rounded" alt={`${!isNaN(basicData.title) ? currentLanguge.appartmentShort.apartment : ''} ${basicData.title}`} />
			</div>
			
		</Modal>
	);
};
export default DetailsApprovalModal;
