import Button from "../../UI/Button/Button";
import "./ApartmentViewChange.sass";
import { setCurrentStep, backURLHistory, stepsDictionary, getObjKey } from "../../../Helpers/AppartmentReserveUtils";
import store from "../../../Redux/Store/store";
import * as actions from "../../../Redux/Reducers/projectSlice";
import { useEffect, useState } from "react";
import $ from "jquery";
import { useSelector } from "react-redux";

const ApartmentViewChange = (props) => {
	const meetingSlug = useSelector((state) => state.verification.slug);
	const currentLanguge = useSelector((state) => state.project.currentLanguge)
	const currentStep = useSelector((state) => state.project.currentStep)
    const show3DView = useSelector((state) => state.project.show3DView)
	const project_3dapikey = useSelector((state) => state.project.projectSettings.project_3dapikey)
	const [pre3DviewURL, setPre3DviewURL] = useState('');
	const [pre3DviewStep, setPre3DviewStep] = useState('');
	
	useEffect(() => { }, [currentLanguge]);

	const setHistoryAfter3D = () => {
		if (window.stepHistory.length <= 2) {
			setCurrentStep(null, "building_selection", true);
		} else {
			backURLHistory(pre3DviewURL);
			let newStep_title = getObjKey(stepsDictionary, parseFloat(pre3DviewStep));
			if(newStep_title && newStep_title != '') setCurrentStep(null, newStep_title, true);
		}
	}

	useEffect(() => {
		$('.navViewChange button').off('click').on("click", (e) => {
			if (e.currentTarget.id === "tableViewButton") {
				setCurrentStep(null, "table_view", true);

				if (show3DView === true) {
					store.dispatch(actions.setShow3DView(false));
				} 
			} else if (e.currentTarget.id === "graphicViewButton") {
				setCurrentStep(null, "graphic_view", true);

				if (show3DView === true) {
					store.dispatch(actions.setShow3DView(false));
				}
			} else if (e.currentTarget.id === "view3DViewButton") {
				if (show3DView === false) {
					setPre3DviewURL(`${window.location.pathname}`);
					setPre3DviewStep(currentStep)

					setCurrentStep(null, "3dview", true);
					store.dispatch(actions.setShow3DView(true));
				} else if (show3DView === true) {
					setHistoryAfter3D()
					if (show3DView === true) store.dispatch(actions.setShow3DView(false));
				}
				return;
			}
		})
		
	}, [meetingSlug, show3DView, currentStep]);


	useEffect(() => {
		if (currentStep == 9) {
			$('.graphicViewButton').addClass('activeView');
			$('.graphicViewButton').find('img').attr('src', '/assets/img/icons/ico-buildings-light.svg');

			$('.tableViewButton').removeClass('activeView');
			$('.tableViewButton').find('img').attr('src', '/assets/img/icons/ico-table.svg');

		} else if (currentStep == 10) {
			$('.tableViewButton').addClass('activeView');
			$('.tableViewButton').find('img').attr('src', '/assets/img/icons/ico-table-light.svg');

			$('.graphicViewButton').removeClass('activeView');
			$('.graphicViewButton').find('img').attr('src', '/assets/img/icons/ico-buildings.svg');
		} else {
			$('.graphicViewButton').removeClass('activeView');
			$('.graphicViewButton').find('img').attr('src', '/assets/img/icons/ico-buildings.svg');

			$('.tableViewButton').removeClass('activeView');
			$('.tableViewButton').find('img').attr('src', '/assets/img/icons/ico-table.svg');
		}
	},[currentStep])

	return (
		<div className="container pds-lg-3 pds-4 py-lg-0 py-md-3 position-relative" id={props.id} key={props.id}>
			{/* <div className="loadingCover position-absolute h-100 w-100" id="loadingCover"/> */}
			<div className="row gx-lg-2 gy-md-2 flex-nowrap justify-content-lg-end justify-content-md-start">
				{/* Table View */}
				<div className="col text-nowrap fw-bold px-lg-2 px-md-0 nav-section-title col d-lg-none">{currentLanguge.apartmentViewChangeCTA.title}</div>

				{/* 3D View */}
				<div className={`col-md-auto px-md-0 px-lg-1 navViewChange navViewChangeview3D ${project_3dapikey && project_3dapikey != '' ? '' : 'd-none'}`} id="view3DViewdiv">
					<Button type="button" className="text-nowrap fw-bolder view3DViewButton" id="view3DViewButton"  dataGtCategory='view change' dataGtAction='click' dataGtLabel='3D view'>
						{
							show3DView &&
								<img src="/assets/img/icons/3d-view-light.png" alt={currentLanguge.apartmentViewChangeCTA.view3D} height="25" width="25" />
						}
						{
							!show3DView &&
								<img src="/assets/img/icons/3d-view.png" alt={currentLanguge.apartmentViewChangeCTA.view3D} height="25" width="25" />
						}
						{show3DView === false ? currentLanguge.apartmentViewChangeCTA.view3D : currentLanguge.apartmentViewChangeCTA.hide3D}
					</Button>
				</div> 

				{/* Table View */}
				<div className="col-md-auto px-md-0 px-lg-1 navViewChange navViewChangeTable" id="tableViewdiv">
					<Button type="button" className="text-nowrap fw-bolder tableViewButton" id="tableViewButton" dataGtCategory='view change' dataGtAction='click' dataGtLabel='table view'>
						<img src="/assets/img/icons/ico-table.svg" alt={currentLanguge.apartmentViewChangeCTA.tableView} height="25" width="25" /> {currentLanguge.apartmentViewChangeCTA.tableView}
					</Button>
				</div>
				{/* Graphic View */}
				<div className="col-md-auto px-md-0 px-lg-1 navViewChange navViewChangeGraphic" id="graphicViewdiv">
					<Button type="button" className="text-nowrap fw-bolder graphicViewButton" id="graphicViewButton" dataGtCategory='view change' dataGtAction='click' dataGtLabel='graphic view'>
						<img src="/assets/img/icons/ico-buildings.svg" alt={currentLanguge.apartmentViewChangeCTA.graphicView} height="25" width="25" /> {currentLanguge.apartmentViewChangeCTA.graphicView}
					</Button>
				</div>

				
			</div>
		</div>
	);
};

export default ApartmentViewChange;
